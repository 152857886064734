import * as React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'gatsby';
import { ClassicLayout } from '../modules/layout/components/classic-layout';

import './index.scss';
import { Seo } from '../modules/common/components/seo';

const NoFoundPage: React.FC = () => {
  return (
    <ClassicLayout>
      <Row className="hero-section align-items-center justify-content-center error-404">
        <Col className="text-center" xs={12} lg={12} xl={8}>
          <h1>404 - Page Not Found</h1>
          <p>
            The page you were looking for could not be found. It might have been
            removed, renamed, or did not exist in the first place.
          </p>
          <Link to="/">
            <Button variant="primary">Back to homepage</Button>
          </Link>
        </Col>
      </Row>
    </ClassicLayout>
  );
};

export default NoFoundPage;

export const Head: React.FC = () => (
  <Seo
    title="404 | Prydwen Institute"
    description="Prydwen.gg provides news, tier lists and detailed guides for Honkai: Star Rail, NIKKE, AFK Journey, Wuthering Waves, Zenless Zone Zero, Reverse 1999 and other gacha games. Everything you need in one place!"
  />
);
